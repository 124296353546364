import React, { Component } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import iconGirl from '../../assets/icon-girl.png';
import btnGirl from '../../assets/btn-girl.png';
import iconMVP from '../../assets/icon-mvp.png';
import btnMVP from '../../assets/btn-mvp.png';
import btnRule from '../../assets/btn-rule.png';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowRule: false
        };
    }

    showRule() {
        this.setState({
            isShowRule: true
        });
    }

    hideRule() {
        this.setState({
            isShowRule: false
        });
    }

    render() {
        const { isShowRule } = this.state;

        return (
            <div className="home-container">
                <div className="action-wrapper clearfix">
                    <Link to={{ pathname: '/girl' }}>
                        <div className="left">
                            <img src={iconGirl} alt="icon-girl" className="icon" />
                            <img src={btnGirl} alt="btn-girl" className="btn" />
                        </div>
                    </Link>
                    <Link to={{ pathname: '/city' }}>
                        <div className="right">
                            <img src={iconMVP} alt="icon-mvp" className="icon" />
                            <img src={btnMVP} alt="btn-mvp" className="btn" />
                        </div>
                    </Link>
                </div>
                <img src={btnRule} alt="rule" className="rule" onClick={this.showRule.bind(this)} />
                <div className="rule-modal" style={isShowRule ? { display: 'block' } : {}}>
                    <div className="rule-bg">
                        <div className="close-btn" onClick={this.hideRule.bind(this)}>x</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;