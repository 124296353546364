import { restUrl } from './config';
import axios from 'axios';
import qs from 'qs';
// import Toast from 'antd-mobile/lib/toast';
// import 'antd-mobile/lib/toast/style/css';

// axios.defaults.withCredentials = true;

class Request {

    request(params) {
        params.url = restUrl + params.url;

        if (!params.type) {
            params.type = 'get';
        }

        // Toast.loading('拼命加载中...', 0);

        switch (params.type) {
            case 'get':
                return this.get(params);
            case 'post':
                return this.post(params);
            case 'put':
                return this.put(params);
            case 'delete':
                return this.delete(params);
            default:
                return this.get(params);
        }
    }

    get(params) {
        return axios.get(params.url, { params: params.params });
    }

    post(params) {
        return axios.post(params.url, qs.stringify(params.params));
    }

    upload(params) {
        const upload = axios.create({
            timeout: 10000,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return upload.post(restUrl + params.url, params.params);
    }
}

export default new Request();
