import React, { Component } from 'react';
import './index.scss'
import avatarBg from '../../assets/avatar-bg.png';
import submitBtn from '../../assets/submit-btn.png';
import submitSuccessWrapper from '../../assets/submit-success-wrapper.png';
import returnBtn from '../../assets/return-btn.png';
import request from '../../utils/request';
import { Link } from 'react-router-dom';
import Toast from 'antd-mobile/lib/toast';

class Form extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                avatar: '',
                name: '',
                school: '',
                self_desc: '',
            },
            isSubmitSuccess: false
        };
    }

    uploadAvatar(e) {
        e.preventDefault();

        const file = e.target.files[0];

        let formData = new FormData();
        formData.append('avatar', file);

        Toast.loading('头像上传中...');

        request.upload({
            url: 'upload',
            params: formData
        }).then(res => {
            Toast.hide();
            
            this.setState({
                form: {
                    ...this.state.form,
                    avatar: res.data.data.url
                }
            });
        });
    }

    updateForm(e) {
        const { name: key, value: val } = e.target;
        const { form } = this.state;
        form[key] = val;
        this.setState({
            form
        });
    }

    submit() {
        const params = this.state.form;
        const that = this;
        request.request({
            type: 'post',
            url: 'girl/save',
            params
        }).then(res => {
            if (res.data.errorCode === 0) {
                that.setState({
                    isSubmitSuccess: true
                });
            }
        });
    }

    render() {
        let { form, isSubmitSuccess } = this.state;
        return (
            <div className="form-container">
                <div className="form-wrapper">
                    <div className="avatar-wrapper">
                        <img src={avatarBg} alt="avatar-bg" className="avatar-bg" />
                        <input type="file" name="avatar" accept="image/*" onChange={this.uploadAvatar.bind(this)} />
                        <img src={form.avatar} alt="" className="avatar" />
                    </div>
                    <div className="input-wrapper name-wrapper">
                        <input className="input" type="text" name="name" value={form.name} onChange={this.updateForm.bind(this)} />
                    </div>
                    <div className="input-wrapper school-wrapper">
                        <input className="input" type="text" name="school" value={form.school} onChange={this.updateForm.bind(this)} />
                    </div>
                    <div className="input-wrapper desc-wrapper">
                        <input className="input" type="text" name="self_desc" value={form.self_desc} onChange={this.updateForm.bind(this)} />
                    </div>
                </div>
                <img src={submitBtn} alt="submit-btn" className="submit-btn" onClick={this.submit.bind(this)} />
                <div className="success-modal" style={isSubmitSuccess ? { display: 'block' } : {}}>
                    <img src={submitSuccessWrapper} alt="success-wrapper" className="success-wrapper" />
                    <Link to={{ pathname: '/' }}>
                        <img src={returnBtn} alt="return-btn" className="return-btn" />
                    </Link>
                </div>
            </div>
        );
    }
}

export default Form;