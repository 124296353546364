import React, { Component } from 'react';
import './index.scss';
import cover from '../../assets/cover.png';
import exchangeBtn from '../../assets/exchange-btn.png';
import confirmBtn from '../../assets/confirm-btn.png';
import successWrapper from '../../assets/success-wrapper.png';
import continueBtn from '../../assets/continue-btn.png';
import returnBtn from '../../assets/return-btn.png';
import iconChoose from '../../assets/icon-choose.png';
import request from '../../utils/request';
import Toast from 'antd-mobile/lib/toast';
import 'antd-mobile/lib/toast/style/css';
import { Link } from 'react-router-dom';

class MVP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cityName: '',
            list: [],
            id: 0,
            code: '',
            isVoteSuccess: false
        };
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        this.setState({
            id
        });
        this._loadData(id);

        let timer = null;
        // window.addEventListener('scroll', function () {
        //     // 截流，50ms间隔
        //     if (typeof timer === 'number') {
        //         clearTimeout(timer);
        //     }
        //     timer = setTimeout(function () {
        //         console.log(1111);

        //         var _clientHeight = document.getElementsByClassName('girl-wrapper').clientHeight;
        //         var list = document.getElementsByClassName('one-girl');
        //         if (list[list.length - 1].getBoundingClientRect().top <= _clientHeight) {
        //             console.log(666667);
        //         }

        //     }, 50);

        // }, false);
    }

    _loadData(_id) {
        const that = this;
        const id = _id || this.state.id;
        request.request({
            url: 'city/' + id
        }).then(res => {
            that.setState({
                cityName: res.data.data.name
            });
        });
        request.request({
            url: 'mvp/' + id
        }).then(res => {
            const list = res.data.data;
            for (let i in list) {
                list[i].isChoosed = false;
            }
            that.setState({
                list
            });
            // console.log(list);
        });
    }

    checkCode() {
        const that = this;
        const { code } = that.state;
        if (code.trim().length !== 15) {
            Toast.info('请输入正确的兑换码！');
            return;
        }
        request.request({
            type: 'post',
            url: 'code/check',
            params: {
                code
            }
        }).then(res => {
            if (res.data.errorCode === 0) {
                Toast.info('兑换成功～');
                that.setState({
                    code: ''
                });
            } else {
                Toast.info('兑换失败！');
            }
        });
    }

    updateCode(e) {
        const code = e.target.value;
        this.setState({
            code
        });
    }

    chooseOne(_index) {
        const { list } = this.state;
        list.forEach((item, index) => {
            if (index === _index) {
                item.isChoosed = !item.isChoosed
            } else {
                item.isChoosed = false;
            }
        });
        this.setState({
            list
        });
    }

    chooseMul(index) {
        const { list } = this.state;
        list[index].isChoosed = !list[index].isChoosed;
        this.setState({
            list
        });
        console.log(list);
    }

    check() {
        let isChecked = false;
        const { list } = this.state;
        for (let i in list) {
            if (list[i].isChoosed === true) {
                isChecked = list[i].id;
                break;
            }
        }
        return isChecked;
    }

    vote() {
        const that = this;
        const id = that.check();
        if (id) {
            request.request({
                type: 'post',
                url: 'mvp/vote',
                params: {
                    token: window.sessionStorage.getItem('token'),
                    id
                }
            }).then(res => {
                if (res.data.errorCode === 0) {
                    that.setState({
                        isVoteSuccess: true
                    });
                } else if (res.data.errorCode === 10001) {
                    Toast.info('投票失败，请重新投票');
                } else if (res.data.errorCode === 10002) {
                    Toast.info('今日您已投过票啦，请明日再来～');
                }
            });
        } else {
            Toast.info('请先选择一个MVP');
        }
    }

    hideSuccessModal() {
        this.setState({
            isVoteSuccess: false
        });
        this._loadData();
    }

    goLink(flag) {
        if (flag === 1) {
            window.location.href = 'https://item.jd.com/100000069576.html';
        } else {
            window.location.href = 'https://item.jd.com/100000069550.html';
        }
    }

    render() {
        const { cityName, list, code, isVoteSuccess } = this.state;

        return (
            <div className="mvp-container">
                <div className="exchange-wrapper">
                    <div className="input-wrapper">
                        <input className="code-input" type="text" value={code} onChange={this.updateCode.bind(this)} />
                    </div>
                    <img src={exchangeBtn} alt="btn" className="btn" onClick={this.checkCode.bind(this)} />
                </div>
                <div className="city-name">{cityName}</div>
                <div className="mvp-wrapper clearfix">
                    {
                        list.map((item, index) => {
                            return (
                                <div className="one-mvp" key={item.id} onClick={this.chooseOne.bind(this, index)}>
                                    <div className="wrapper clearfix">
                                        <img src={item.avatar} alt="cover" className="cover" />
                                        <div className="num-wrapper" style={item.isChoosed ? { display: 'block' } : {}}>票数{item.score}</div>
                                        <div className="right-content">
                                            <div className="name">姓名：{item.name}</div>
                                            <div className="city">学校：{item.school}</div>
                                            <div className="pos">位置：{item.pos}</div>
                                        </div>
                                    </div>
                                    <img src={iconChoose} alt="iconChoose" className="iconChoose" style={item.isChoosed ? { display: 'block' } : {}} />
                                </div>
                            );
                        })
                    }
                </div>
                <div className="buy-label">
                    <div className="wrapper label">购买指定产品可以获得更多投票次数</div>
                    <div className="wrapper link-label" onClick={this.goLink.bind(this, 1)}>
                        <span className="link">https://item.jd.com/100000069576.html</span>
                        <span className="btn">去购买</span>
                    </div>
                    <div className="wrapper link-label" onClick={this.goLink.bind(this, 2)}>
                        <span className="link">https://item.jd.com/100000069550.html</span>
                        <span className="btn">去购买</span>
                    </div>
                </div>
                <img src={confirmBtn} alt="confirm-btn" className="confirm-btn" onClick={this.vote.bind(this)} />
                <div className="success-modal" style={isVoteSuccess ? { display: 'block' } : {}}>
                    <img src={successWrapper} alt="success-wrapper" className="success-wrapper" />
                    <img src={continueBtn} alt="continue-btn" className="continue-btn" onClick={this.hideSuccessModal.bind(this)} />
                    <Link to={{ pathname: '/' }}>
                        <img src={returnBtn} alt="return-btn" className="return-btn" />
                    </Link>
                </div>
                <div className="buy-wrapper" onClick></div>
            </div>
        );
    }
}

export default MVP;