import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import Home from './pages/Home/';
import Girl from './pages/Girl/';
import MVP from './pages/MVP/';
import Form from './pages/Form/';
import City from './pages/City/';

const Routes = () => (
    <Fragment>
        <Route exact path="/" component={Home}></Route>
        <Route path="/girl" component={Girl}></Route>
        <Route path="/mvp/:id" component={MVP}></Route>
        <Route path="/form" component={Form}></Route>
        <Route path="/city" component={City}></Route>
    </Fragment>
);

export default Routes;
