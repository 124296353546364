import React, { Component } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import './app.css';
import request from './utils/request'

class App extends Component {

  componentDidMount() {
    request.request({
      url: 'token'
    }).then(res => {
      window.sessionStorage.setItem('token', res.data.data.token);
    });
  }

  render() {
    return (
      <div className="app">
        <Router>
          <Routes></Routes>
        </Router>
      </div>
    );
  }
}

export default App;
