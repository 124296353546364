import React, { Component, Fragment } from 'react';
import './index.scss';
import { Link } from 'react-router-dom';
import request from '../../utils/request';

class Girl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: []
        };
    }

    componentDidMount() {
        this._loadData();
    }

    _loadData() {
        const that = this;
        request.request({
            url: 'city'
        }).then(res => {
            that.setState({
                list: res.data.data
            });
        });
    }

    render() {
        const { list } = this.state;
        return (
            <div className="city-container">
                <div className="city-wrapper">
                    {
                        list.map(item => {
                            return (
                                <Fragment key={item.id}>
                                    {
                                        item.is_able === 1 ?
                                            (
                                                <Link to={{ pathname: `/mvp/${item.id}` }}>
                                                    <div className="one">
                                                        <div className="name">{item.name}</div>
                                                        <img src={item.img_url} alt="city-cover" className="city-cover" />
                                                    </div>
                                                </Link>
                                            ) : (
                                                <div className="one">
                                                    <div className="name">{item.name}</div>
                                                    <div className="tip">—— 静待开启 ——</div>
                                                    <img src={item.img_url} alt="city-cover" className="city-cover" />
                                                    <div className="mask"></div>
                                                </div>
                                            )
                                    }
                                </Fragment>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}

export default Girl;